import React, { useEffect, useState } from 'react';

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('https://grimmxiii-com-backend.onrender.com/api/blog')
      .then((response) => response.json())
      .then((data) => setPosts(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <h1>Blog Posts</h1>
      {posts.map((post) => (
        <div key={post._id}>
          <h2>{post.title}</h2>
          <p>{post.content}</p>
        </div>
      ))}
    </div>
  );
};

export default Blog;




/*

Alright boys, we startin this shit.
If your reading this, then congrats, you are visiting a page that
is currently a work in progress with a database :D

yeah im learning from tutorials and documentation, but its bout time
i got something running.

Hopefully maybe I can get these out soon perhaps?
in the meantime, this will remain looking weird

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
export default function Home() {
    const [location, setLocation] = useLocation();
    let num = 0;

    useEffect(() => {
        const socialButtons = document.querySelectorAll('.social-email, .social-twitter, .social-spotify, .social-soundcloud, .social-youtube-solo, .social-youtube-trio, .social-discord, .social-gamebanana, .social-github, .social-gamejolt, .social-steam, .social-epic-games, .social-playstation, .social-minecraft, .social-tetrio');
  
        socialButtons.forEach(button => {
            const imgClass = button.classList[0].replace('social-', 'img-');
            const img = document.querySelector(`.${imgClass}`);
            const oldImg = document.querySelector(`.img-old`);

            if (img) {
                button.addEventListener('mouseenter', () => {
                    img.style.display = 'inline-block';
                    oldImg.style.display = 'none';
                });
    
                button.addEventListener('mouseleave', () => {
                    img.style.display = 'none';
                    oldImg.style.display = 'inline-block';
                });
            }
        });
    }, []);
  
    const warning = () => {
        const mainElem = document.getElementById("warning");
        const boom = new Audio('misc/boom.mp3');

        if (num < 5) {
            mainElem.classList.remove("shake");
            void mainElem.offsetWidth;
            mainElem.classList.add("shake");
            num = num + 1;
        } else if (num >= 5) {
            boom.play();
            mainElem.classList.add("shakeBadder");
            num = num + 1;
        }
    };
  
    function nopeShake(num) {
        const mainElem = document.getElementById(num === 1 ? "epicBad" : "minecraftBad");

        mainElem.classList.remove("shake");
        void mainElem.offsetWidth;
        mainElem.classList.add("shake");
    };
  
    const proj = (num, link) => {
        const button = document.getElementById("proj" + num);
        const warningDiv = document.getElementById("warning");
        button.classList.add('projLinkAnim');

        setTimeout(() => {
            if (num === '1' || num === '2' || num === '3') {
                setLocation("/" + link)

            } else {
                // window.scrollTo({ top: 0, behavior: 'smooth' });
                // warning();
            }
        }, 1000);
  
        setTimeout(() => {
            button.classList.remove('projLinkAnim');
            void button.offsetWidth;
            button.classList.add('projLinkAnimBack');
        }, 5000);

        setTimeout(() => {
            button.classList.remove('projLinkAnimBack');
            void button.offsetWidth;
        }, 6000);
    };
  
const about = () => {
const aboutButton = document.getElementById('aboutButton');
const navButtons = document.getElementsByClassName('navButton');
const navButton = document.getElementById('navButton2');
aboutButton.classList.add('aboutButtonMove');

setTimeout(() => {
    for (let i = 0; i < navButtons.length; i++) {
        navButtons[i].classList.add('navButtonGlow');
    }}, 500);

    setTimeout(() => {
        warning();
    }, 1500);

    setTimeout(() => {
        aboutButton.classList.remove('aboutButtonMove');
        void aboutButton.offsetWidth;
        aboutButton.classList.add('aboutButtonBack');
    }, 5000);

    setTimeout(() => {
        aboutButton.classList.remove('aboutButtonBack');
        void aboutButton.offsetWidth;
        for (let i = 0; i < navButtons.length; i++) {
            navButtons[i].classList.remove('navButtonGlow');
        }
    }, 6000);
};

return (
    <>  
        <title>Grimm's Corner</title>
        <div id='warning' className="warning">
            <img src="/images/warning.png" />
            <h2>This website is legit being developed on!</h2>
            <p>This website is a work in progress, I didnt share the link, so good job on getting here! but dont expect much, the reason is up is so I can test er out. But hey, nice to see ya, stop by every now and again to see sweet ass updates! :)</p>
            <p>Just realized that I left the link on Discord. but hey, still glad your're here!</p>
        </div>
        <h1>
            GrimmXIII Edition
        </h1>
        <main id='main'>
            
        </main>






    </>
    );
}


*/